import styled from 'styled-components';
import media from './mediaQuery'
import { theme } from '../global'

export const List = styled.ul`
  margin-top: 10px;
  width: 100%;

  ${media.tablet`
    flex-basis: calc(100% * (8 / 8));
    max-width: calc(100% * (8 / 8));
    margin-top: 15px;
  `}

  ${media.tabletLarge`
    flex-basis: calc(100% * (12 / 12));
    max-width: calc(100% * (12 / 12));
  `}
`;

export const Item = styled.li`
  display: inline-flex;
  margin: 0 0 10px;
  position: relative;
  width: 100%;
  
  ${media.tablet`
    margin: 0 30px 15px 0;
    width: fit-content;
  `}

  &:after {
    
    ${media.tablet`
      content: '/';
      color: ${theme.colors.white};
      display: block;
      font-size: 1.625rem;
      line-height: 1.8125rem;
      position: absolute;
      right: -20px;
      top: 1px;
    `}
  }

  &:last-of-type {
    margin-bottom: 0;

    &:after {
      display: none;
    }
  }
`;
import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import media from '../components/common/mediaQuery'
import { H2, Type, Caption } from '../components/common/type'
import { List, Item } from '../components/common/listItems'
import { Anchor } from '../components/common/anchor'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    
    <LeadContent>
      <Meta>
        <H2 as="h1">Oneil Olonade</H2>
      </Meta>
      <Meta>
        <Type as="h2">Web Engineer / Manager</Type>
        <Caption>
          <Anchor href="mailto:hello@oneilolonade.com">hello@oneilolonade.com</Anchor>
        </Caption>
      </Meta>
    </LeadContent>

    <Row>
      <Caption as="h2">Selected Works</Caption>
      <List>
        <Item>
          <Anchor href="https://softservices.co/" target="_blank" rel="noreferrer">Soft Serivces</Anchor>
        </Item>
        <Item>
          <Anchor href="https://yourparade.com/" target="_blank" rel="noreferrer">Parade</Anchor>
        </Item>
        <Item>
          <Anchor href="https://votesaveamerica.com/" target="_blank" rel="noreferrer">Vote Save America</Anchor>
        </Item>
        <Item>
          <Anchor href="https://ourtreaty.com/" target="_blank" rel="noreferrer">Treaty</Anchor>
        </Item>
        <Item>
          <Anchor href="https://www.elkineditions.com/" target="_blank" rel="noreferrer">Elkin Editions</Anchor>
        </Item>
        <Item>
          <Anchor href="https://weekendhome.co/" target="_blank" rel="noreferrer">Weekend Home</Anchor>
        </Item>
        <Item>
          <Anchor href="https://baboontothemoon.com/" target="_blank" rel="noreferrer">Baboon To the Moon</Anchor>
        </Item>
        <Item>
          <Anchor href="https://www.backdrophome.com/" target="_blank" rel="noreferrer">Backdrop</Anchor>
        </Item>
        <Item>
          <Anchor href="https://bombas.com/" target="_blank" rel="noreferrer">Bombas</Anchor>
        </Item>
      </List>
    </Row>
  </Layout>
)

export default IndexPage

const LeadContent = styled.div`
  align-items: center;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  margin-bottom: 0;
  padding: 0;
  width: 100%;
`;

const Meta = styled.div`
  margin-top: 2px;
  width: 100%;

  ${media.tablet`
    flex-basis: calc(100% * (5 / 8));
    max-width: calc(100% * (5 / 8));
    margin-top: 0;
  `}

  ${media.tabletLarge`
    flex-basis: calc(100% * (6 / 12));
    max-width: calc(100% * (6 / 12));
  `}

  &:last-of-type {

    ${media.tablet`
      flex-basis: initial;
      max-width: initial;
      margin-left: auto;
      width: fit-content;
    `}

    ${media.tabletLarge`
    `}
  }

  h2 {

    ${media.tablet`
      margin-top: 6px;
    `}
  }
`;

const Row = styled.div`
  margin-top: 80px;
  width: fit-content;
  
  ${media.tablet`
    bottom: 80px;
    margin-top: 0;
    position: absolute;
    width: calc(100% - 80px);
  `}

  ${media.tabletLarge`
    width: calc(100% - 120px);
  `}
`;